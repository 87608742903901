import React from 'react';
import Zoom from 'react-reveal/Zoom';

import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
//import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: '',
    paragraph: ''
  };

  return (
    <section id="sobre"

      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <Zoom left>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Pós-Graduação
                    </h4>
                    <p className="m-0 text-sm">
                      Puc/Rs 2008
                      Hospital Cristo Redentor
                    </p>
                  </div>
                </div>
              </div>
            </Zoom>

            <Zoom bottom>
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Residência
                    </h4>
                    <p className="m-0 text-sm">
                      Em Cirurgia e Traumatologia BucomaxiloFacial – UFPR 2009/2011
                      Especialista pela UFPR e Colégio Brasileiro de CTBMF
                    </p>
                  </div>
                </div>
              </div>
            </Zoom>

            <Zoom right>
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="800">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Mestrado
                    </h4>
                    <p className="m-0 text-sm">
                      Estomatologia - PUC/PR 2016/2017
                    </p>
                  </div>
                </div>
              </div>
            </Zoom>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
