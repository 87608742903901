import React, { useState } from 'react';

const Banner = ({
  ...props
}) => {

  return (
    <>
    </>
  );
}

export default Banner;
