import React from 'react';
import Zoom from 'react-reveal/Zoom';

import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Área de Atuação',
    paragraph: ''
  };

  return (
    <section id="area"
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <Zoom top>
            <SectionHeader data={sectionHeader} className="center-content" />
          </Zoom>

          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <Zoom bottom>
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Cirurgia Ortognática
                  </div>
                  <h3 className="mt-0 mb-12">
                  </h3>
                </Zoom>

                <Zoom top>
                  <p className="m-0">
                    Conjunto de procedimentos cirúrgicos para corrigir problemas dentários e esqueléticos, além de eliminar as deformidades dentofaciais, promover a harmonia e a melhoria funcional da face. Sua realização consiste no reposicionamento da maxila, da mandíbula ou do mento (queixo).
                  </p>
                </Zoom>
              </div>
              <Zoom right>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/features-split-image-01.png')}
                    alt="Features split 01"
                    width={528}
                    height={396} />
                </div>
              </Zoom>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <Zoom bottom>
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Traumatologia Buco Maxilo Facial
                  </div>
                </Zoom>

                <h3 className="mt-0 mb-12">
                </h3>
                <Zoom top>
                  <p className="m-0">
                    Trata as doenças da cavidade oral e seus anexos, tais como: traumatismos e deformidades faciais (congênitos ou adquiridos), traumas e deformidades dos maxilares.
                  </p>
                </Zoom>
              </div>

              <Zoom left>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/features-split-image-02.png')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
              </Zoom>

            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <Zoom bottom>
                  <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    Patologias Maxilo Faciais
                  </div>
                </Zoom>

                <h3 className="mt-0 mb-12">
                </h3>

                <Zoom top>
                  <p className="m-0">
                    Tem por objetivo o correto diagnóstico e tratamento das doenças que acometem esta topografia. Assim ela ocupa uma posição única nos cuidados com a saúde.
                  </p>
                </Zoom>
              </div>
              <Zoom right>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={require('./../../assets/images/features-split-image-03.png')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
              </Zoom>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
