import React, { useState } from 'react';
import Zoom from 'react-reveal/Zoom';

import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section id="sobre"
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <Zoom left>
              <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                <br />
                <br />
                Fabiano Galina <span className="text-color-primary">Cirurgia Bucofacial</span>
              </h1>
            </Zoom>
            <Zoom right>
              <div className="container-xs">
                <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Cirurgião BucoMaxiloFacial com mais de 10 anos de experiência. Tem se dedicado exclusivamente a cirurgia ortognática e reabilitação oral com implantes convencionais e zigomáticos, proporcionando aos pacientes mais qualidade de vida e em muitos casos a realização de um sonho.
                </p>
              </div>
            </Zoom>
          </div>
          <Zoom left>
            <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="1000">
              <Image
                className="has-shadow"
                src={require('./../../assets/images/fg1.jpeg')}
                alt="Hero"
                width={896}
                height={504} />
            </div>
          </Zoom>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
