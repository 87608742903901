import React from 'react';
import Zoom from 'react-reveal/Zoom';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <Zoom left>
              <FooterSocial />
            </Zoom>

            <br />
            <br />

            <Zoom right>
              <strong>
                (41)3332-5399
                <br />
                Rua Baltazar Carrasco dos Reis, 2843
                <br />
                Bairro Água Verde
                <br />
                Curitiba - PR
              </strong>
            </Zoom>              
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <div className="footer-copyright">Fabiano Galina. 2023 Todos Direitos Reservados.</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
